<template>
  <div
    :class="[
      'docx-editor',
      { underline: this.$root.config.isTitleLine === 'true' },
    ]"
    ref="docxEditor"
  >
    <div
      v-if="linkError === 1001 || linkError === 1002 || linkError === 1003"
      class="error-mxs-edit"
    >
      <span class="error-exp">{{
        linkError === 1001
          ? "用户认证失败"
          : linkError === 1002
          ? "账户链接太多"
          : "文档链接太多"
      }}</span>
    </div>
    <div v-else-if="linkError === 4001" class="mxs-editor-error-loading">
      <div class="mxs-editor-error-text" @click="onReloadMxsEditor">
        网络异常，重新加载中
      </div>
    </div>
    <editor
      v-else-if="articleId && linkError !== 4001"
      ref="editor"
      className="dox-viewer"
      :initValue="readOnly ? initValue : content"
      :title="title === '新建模型' ? '' : title"
      :user="{ name: Date.now() + '-s', avatar: '' }"
      :articleId="articleId"
      :wss="'wss://' + wss"
      :disabledCollaborative="disabledCollaborative"
      :readOnly="readOnly"
      :showCloseButton="showCloseButton"
      :onConvertFinished="onConvertFinished"
      :isPasteText="$root.config.pastePlain === 'true'"
      :onChangeToolbar="onChangeToolbar"
      :onChangeTitle="onChangeTitle"
      :onBlurTitle="onBlurTitle"
      :onClose="onClose"
      :onLoad="onLoad"
      :contextMenuAuthority="contextMenuAuthority"
      :toolbarMenuAuthority="toolbarMenuAuthority"
      :fontList="$root.config.fontList"
      :token="accessToken"
      :onError="onError"
      :getInitDocument="getInitDocument"
      :onChange="onChangeContent"
      :toolbarDirection="
        $root.config.toolbarType === 'default'
          ? 'none'
          : $root.config.toolbarType
      "
      :theme="$root.config.theme.includes('dark') ? 'dark' : 'light'"
      :isFocus="isFocus"
    />
  </div>
  <upload-image
    v-model:show="showImageUpload"
    :article-id="articleId"
    :original-image="true"
    @success="onUploadImage"
  />
  <div class="mxs-connecting d-center" v-if="connectStatus !== 'finished'">
    <div class="ui-loading"></div>
    <span>加载中</span>
  </div>
</template>

<script>
import { EditorVersion, MxsEditor } from "./word-viewer";
import "./style.css";
import { applyPureReactInVue } from "veaury";
import UploadImage from "./UploadImage";
import base from "../../api/base";
import { htmlToJson } from "./html";

export default {
  name: "MxsEditor",
  inject: ["reload", "passTochatNav", "saveTip"],
  components: {
    UploadImage,
    Editor: applyPureReactInVue(MxsEditor),
  },
  props: {
    disabledCollaborative: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false },
    articleId: { type: String, default: "" },
    title: { type: String, default: "" },
    exts: { type: String, default: "" },
    noNetData: { type: Boolean, default: false },
    initValue: { type: String, default: "" },
    shareId: { type: String, default: "" },
  },
  computed: {
    accessToken() {
      return localStorage.getItem("mindmap_token");
    },
    contextMenuAuthority() {
      // return {
      //   set: true,
      //           paste: true,
      //           copy: true,
      //           pastePlain: true,
      //           embInsertHR: true,
      //           tips: true,
      //           image: true,
      //           math: true,
      //           today: true,
      //           undo: true,
      //           redo: true,
      //           table: true,
      // }
      const map = { set: true, grid: true, pastePlain: true };
      this.$root.config?.editorMenuButtons?.forEach((v) => {
        map[v] = true;
      });
      return map;
    },
    toolbarMenuAuthority() {
      const map = { b: {} };
      this.$root.config?.toolbarButtons?.forEach((v) => {
        map[v] = true;
      });
      map.b.textColor = this.$root.config?.textColor;
      map.b.textColorBg = this.$root.config?.textColorBg;
      map.b.textBgColor = this.$root.config?.textBgColor;
      map.b.textBgBgColor = this.$root.config?.textBgBgColor;
      return map;
    },
  },
  data() {
    return {
      wss: base.yjs,
      isHtml: true,
      content: "",
      showImageUpload: false,
      // connected connecting disconnected
      connectStatus: "connected",
      linkError: null,
      autoWS: false,
    };
  },
  watch: {
    articleId() {
      console.log("-mounted-222");
    },
    "$root.config.lineHeight": {
      handler(val) {
        this.$refs.docxEditor?.style.setProperty(
          "--mxs-default-line-height",
          val
        );
      },
    },
    "$root.config.segmentSpacing": {
      handler(val) {
        this.$refs.docxEditor?.style.setProperty(
          "--mxs-default-margin-bottom",
          val + "em"
        );
      },
    },
  },
  mounted() {
    this.$refs.docxEditor?.style.setProperty(
      "--mxs-default-line-height",
      this.$root.config.lineHeight
    );
    // segmentSpacing
    this.$refs.docxEditor?.style.setProperty(
      "--mxs-default-margin-bottom",
      this.$root.config.segmentSpacing + "em"
    );
  },
  unmounted() {
    if (this.loadTimer) {
      clearTimeout(this.loadTimer);
    }
  },
  methods: {
    onChangeContent() {
      this.saveTip();
    },
    getInitDocument() {
      return this.$api.article.articleDetail(this.articleId).then((res) => {
        const list = htmlToJson({ html: res.data.content });
        // console.log('list', list);
        return { data: list };
      });
    },
    onError(code) {
      this.linkError = code;
      if (code === 1001 || code === 1002 || code === 1003) {
        this.$root.tips(
          "warning",
          code === 1001
            ? "用户认证失败"
            : code === 1002
            ? "账户链接太多"
            : "文档链接太多"
        );
      }
    },
    onReloadMxsEditor() {
      this.linkError = null;
    },
    onLoad(status) {
      this.connectStatus = status;
      if (status === "finished" && !this.$root.cursorElement) {
        clearTimeout(this.loadTimer);
        this.loadTimer = null;
        this.focus();
      } else if (status === "connected") {
        clearTimeout(this.loadTimer);
        this.loadTimer = setTimeout(() => {
          this.linkError = 4001;
          this.$proxy.log({
            key: "editor loading error",
            content: this.articleId,
          });
          if (this.autoWS) {
            this.loadTimer = setTimeout(() => {
              this.linkError = null;
            }, 500);
          }
        }, 5000);
      } else {
        clearTimeout(this.loadTimer);
        this.loadTimer = null;
      }
    },
    isFocus() {
      if (this.$root.cursorElement) {
        this.$root.cursorElement = false;
        return false;
      }
      return true;
    },
    focus() {
      if (!this.$root.cursorElement) {
        this.$refs.editor?.focus();
      }
    },
    recover(content) {
      this.$refs.editor?.changeContent(content);
    },
    onClose() {
      this.$emit("update:showCloseButton", false);
      this.$emit("close");
    },
    onChangeTitle(title) {
      if (title === this.title) return;
      this.isSave = true;
      this.$emit("update:title", title);
      this.$emit("changeTitle", { id: this.articleId, title: title });
    },
    onBlurTitle(title) {
      if (this.isSave) {
        this.isSave = false;
        this.$emit("changeTitle", { id: this.articleId, title: title });
      }
    },
    async onConvertFinished() {
      if (this.readOnly) return;
      await this.$api.article.updateExts({
        articleId: this.articleId,
        exts: EditorVersion,
      });
      const item = this.$root.findItemById(this.articleId);
      if (item) {
        if (item.exts) {
          item.exts.editorVersion = EditorVersion;
        } else {
          item.exts = { editorVersion: EditorVersion };
        }
      }
    },
    fetchData() {
      if (this.noNetData) {
        return;
      }
      this.content = "";
      if (
        this.exts &&
        this.exts.editorVersion &&
        this.exts.editorVersion + "" === EditorVersion
      )
        return;
      this.$api.article.articleDetail(this.articleId).then((res) => {
        this.content = res.data.content;
      });
    },
    onChangeToolbar(type, value) {
      if (type === "more") {
        this.$root.configActive = "config";
        this.$root.configVisible = true;
      } else if (type === "editor-provenance") {
        this.$root.configActive = "tips";
        this.$root.configVisible = true;
      } else if (type === "editor-image") {
        this.showImageUpload = true;
      } else if (type === "transit") {
        this.passTochatNav("transfer", value || "");
      } else if (type === "idea") {
        this.passTochatNav("flashThought", value || "");
      }
    },
    onUploadImage(url) {
      this.$refs.editor.insertImage(url);
    },
    insertText(text) {
      this.$refs.editor.insertText(text);
    },
  },
};
</script>
<style>
.docx-editor {
  --mxs-default-margin-bottom: 0;
}
.docx-dark .dox-toolbar {
  border-radius: 0;
}

.dox-viewer {
  padding: 0;
}
.error-mxs-edit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-exp {
  color: #999;
  padding: 10px;
}
.mxs-editor-error-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mxs-editor-error-text {
  color: #999;
}
.docx-light .mxs-context-menu > div:hover {
  background-color: #f0f0f0;
}
.docx-content {
  line-height: var(--mxs-default-line-height);
}
.underline {
  .dox-inner {
    margin-top: 10px;
    min-height: calc(100% - 10px);
  }
  .docx-dark .title-input input {
    border-bottom: 1px solid #171717;
  }
  .docx-light .title-input input {
    border-bottom: 1px solid #ebebeb;
  }
  .docx-content > div:nth-child(1) {
    height: calc(100% - 10px);
  }
}
</style>
